<template>
  <div class="play-info" :class="playInfoDynamicClasses">
    <!-- retro -->
    <div v-show="showRetro" class="play-info__retro" :class="retroDynamicClasses">
      <div class="play-info__retro__round">{{ playInfo.roundAbbreviation }}</div>
      <div class="play-info__retro__game">
        <div class="logo">
          <img :src="getTeamLogo()" alt="" />
        </div>
        <div class="vs">
          VS
        </div>
        <div class="logo">
          <img :src="getTeamLogo(true)" alt="" />
        </div>
      </div>
      <div class="play-info__retro__detail">
        <p>
          <span class="category-names">{{ playInfo.categoriesNames }} - </span
          ><span>{{ playInfo.playerShortName }}</span>
        </p>
      </div>
    </div>
    <!-- games -->
    <div v-if="infoType === 1" v-show="!showRetro" class="play-info__games" :class="gamesDynamicClasses">
      <div class="play-info__games__rounds">
        <BDropdown
          :value="roundSelected"
          aria-role="list"
          :max-height="dropDownHeight"
          scrollable
          expanded
          @input="onRoundSelected"
        >
          <template #trigger>
            <div class="play-info__games__rounds__round selected">
              <div class="text">
                {{ roundSelected }}
              </div>
              <div class="icn-arrow">
                <img src="/icons/icn_arrow_down.svg" alt="" />
              </div>
            </div>
          </template>
          <BDropdownItem v-for="round of rounds" :value="round" :key="round.name" aria-role="listitem">{{
            round.name
          }}</BDropdownItem>
        </BDropdown>
      </div>
      <div v-if="gameSelected.homeTeam" class="play-info__games__game">
        <BDropdown v-model="gameSelected" aria-role="list" :max-height="dropDownHeight" scrollable expanded>
          <template #trigger>
            <div class="play-info__games__game__teams selected">
              <div>
                <div class="logo">
                  <img :src="gameSelected.homeTeam.logo" alt="" />
                </div>
                <div class="vs">
                  VS
                </div>
                <div class="logo">
                  <img :src="gameSelected.awayTeam.logo" alt="" />
                </div>
              </div>
              <div class="icn-arrow">
                <img src="/icons/icn_arrow_down.svg" alt="" />
              </div>
            </div>
          </template>
          <BDropdownItem v-for="(value, name) in roundGames" :value="value" :key="name" aria-role="listitem">
            <div class="play-info__games__game__teams">
              <div class="logo">
                <img :src="value.homeTeam.logo" alt="" />
              </div>
              <div class="vs">
                VS
              </div>
              <div class="logo">
                <img :src="value.awayTeam.logo" alt="" />
              </div>
            </div>
          </BDropdownItem>
        </BDropdown>
      </div>
      <div class="play-info__games__detail" v-if="playInfo.categoriesNames">
        <p>
          <span class="category-names">{{ playInfo.categoriesNames }} - </span
          ><span>{{ playInfo.playerShortName }}</span>
        </p>
      </div>
    </div>
    <!-- players -->
    <div v-else-if="infoType === 2" v-show="!showRetro" class="play-info__players" :class="playersDynamicClasses">
      <div class="play-info__players__teams">
        <BDropdown
          :value="teamSelected"
          aria-role="list"
          :max-height="dropDownHeight"
          scrollable
          expanded
          @input="onTeamChanged"
        >
          <template #trigger>
            <div class="play-info__players__teams__team selected">
              <div>
                <div class="logo">
                  <img :src="teamSelected.logo" alt="" />
                </div>
                <div class="team-name" v-if="containerWidth < 391">{{ teamSelected.name }}</div>
              </div>
              <div class="icn-arrow">
                <img src="/icons/icn_arrow_down.svg" alt="" />
              </div>
            </div>
          </template>
          <BDropdownItem v-for="team of teams" :value="team" :key="team.id" aria-role="listitem">
            <div class="play-info__players__teams__option">
              <div class="logo">
                <img :src="team.logo" alt="" />
              </div>
              <div>
                {{ team.acronym }}
              </div>
            </div>
          </BDropdownItem>
        </BDropdown>
      </div>
      <div class="play-info__players__list">
        <BDropdown
          :value="playerSelected"
          aria-role="list"
          :max-height="dropDownHeight"
          scrollable
          expanded
          @input="onPlayerSelected"
        >
          <template #trigger>
            <div class="play-info__players__list__player selected">
              <div>
                {{ playerSelected.shortName }}
              </div>
              <div class="icn-arrow">
                <img src="/icons/icn_arrow_down.svg" alt="" />
              </div>
            </div>
          </template>
          <BDropdownItem v-for="player of teamSelected.players" :value="player" :key="player.id" aria-role="listitem">
            <div class="play-info__players__list__option">
              <div>
                {{ player.shortName }}
              </div>
            </div>
          </BDropdownItem>
        </BDropdown>
      </div>
      <div class="play-info__players__detail category-names">
        {{ playInfo.categoriesNames }}
      </div>
      <div class="play-info__players__rival rival-team">
        <div class="vs">
          VS
        </div>
        <div>
          <div class="logo">
            <img :src="playInfo.rivalLogo" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- teams -->
    <div v-else v-show="!showRetro" class="play-info__teams" :class="teamsDynamicClasses">
      <div class="play-info__teams__list">
        <BDropdown
          :value="teamSelected"
          aria-role="list"
          :max-height="dropDownHeight"
          scrollable
          expanded
          @input="onTeamSelected"
        >
          <template #trigger>
            <div class="play-info__teams__list__team selected">
              <div>
                <div class="logo">
                  <img :src="teamSelected.logo" alt="" />
                </div>
                <div class="team-name">
                  {{ teamSelected.name }}
                </div>
              </div>
              <div class="icn-arrow">
                <img src="/icons/icn_arrow_down.svg" alt="" />
              </div>
            </div>
          </template>
          <BDropdownItem v-for="team of teams" :value="team" :key="team.id" aria-role="listitem">
            <div class="play-info__players__teams__option">
              <div class="logo">
                <img :src="team.logo" alt="" />
              </div>
              <div>
                {{ team.acronym }}
              </div>
            </div>
          </BDropdownItem>
        </BDropdown>
      </div>
      <div class="play-info__teams__detail" v-if="playInfo.categoriesNames">
        <span>
          <span class="category-names">{{ playInfo.categoriesNames }} - </span
          ><span>{{ playInfo.playerShortName }}</span>
        </span>
      </div>
      <div class="play-info__teams__rival rival-team">
        <div class="vs">
          VS
        </div>
        <div>
          <div class="logo">
            <img :src="playInfo.rivalLogo" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'buefy/dist/esm/dropdown';
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'PlayInfo',
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    infoType: {
      type: Number,
      default: 1,
    },
    gamesByRound: {
      type: Object,
      default: () => {},
    },
    teams: {
      type: Array,
      default: () => [],
    },
    currentPlay: {
      type: Object,
      default: () => {},
    },
    playInfo: {
      type: Object,
      default: () => {},
    },
    containerWidth: {
      type: Number,
      default: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    },
    isRetro: {
      type: Boolean,
      default: false,
    },
    isRetroModeRequested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      roundSelected: 'M17',
      gameSelected: {},
      teamSelected: {},
      playerSelected: {},
      roundGames: [],
      playInfoDynamicClasses: [],
      gamesDynamicClasses: [],
      playersDynamicClasses: [],
      teamsDynamicClasses: [],
      retroDynamicClasses: [],
    };
  },
  computed: {
    ...mapState(['displayWidth']),
    rounds() {
      return Object.entries(this.gamesByRound).map(([key, value]) => ({ key, name: value.name }));
    },
    showRetro() {
      return this.isRetro && this.isRetroModeRequested;
    },
    dropDownHeight() {
      return this.displayWidth < 1023 ? 416 : 304;
    },
  },
  watch: {
    playInfo() {
      if (!this.isRetroModeRequested) {
        this.setInfoData();
      }
    },
    infoType() {
      this.setInfoData();
    },
    containerWidth: {
      immediate: true,
      handler(newValue) {
        this.playInfoDynamicClasses = [];
        this.gamesDynamicClasses = [];
        this.playersDynamicClasses = [];
        this.teamsDynamicClasses = [];
        this.retroDynamicClasses = [];
        if (newValue < 921) {
          this.playersDynamicClasses.push('players-first-break-point');
          if (newValue < 711) {
            this.gamesDynamicClasses.push('games-first-break-point');
            this.retroDynamicClasses.push('retro-first-break-point');
            if (newValue < 701) {
              this.teamsDynamicClasses.push('teams-first-break-point');
              if (newValue < 677) {
                this.playInfoDynamicClasses.push('play-info-first-break-point');
                if (newValue < 551) {
                  this.playersDynamicClasses.push('players-second-break-point');
                  if (newValue < 461) {
                    this.teamsDynamicClasses.push('teams-second-break-point');
                    this.retroDynamicClasses.push('retro-second-break-point');
                    if (newValue < 391) {
                      this.playersDynamicClasses.push('players-third-break-point');
                      this.gamesDynamicClasses.push('games-second-break-point');
                    }
                  }
                }
              }
            }
          }
        }
      },
    },
    gameSelected(newValue) {
      this.$emit('game-id-select', newValue.id);
    },
  },
  methods: {
    ...mapMutations('mediaGeneral', ['setTeam', 'setPlayer']),
    setInfoData() {
      if (this.infoType === 1) {
        this.roundSelected = this.playInfo.roundAbbreviation;
        this.roundGames = this.gamesByRound[this.roundSelected].games;
        this.gameSelected = this.roundGames.find(game => game.id === this.playInfo.gameId);
      } else if (this.infoType === 2 || this.infoType === 3) {
        this.teamSelected = this.teams.find(team => team.id === this.playInfo.teamId);
        if (this.infoType === 2) {
          this.playerSelected = this.teamSelected.players.find(player => player.id === this.playInfo.playerId);
        }
      }
    },
    getTeamLogo(isAwayTeam = false) {
      if (!isAwayTeam) {
        return this.playInfo.asLocal ? this.playInfo.teamLogo : this.playInfo.rivalLogo;
      } else {
        return this.playInfo.asLocal ? this.playInfo.rivalLogo : this.playInfo.teamLogo;
      }
    },
    onRoundSelected(round) {
      this.roundSelected = round.key;
      this.roundGames = this.gamesByRound[this.roundSelected].games;
      this.gameSelected = this.roundGames[0];
    },
    onTeamChanged(team) {
      this.teamSelected = team;
      this.setPlayerSelected(this.teamSelected.players[0]);
    },
    onPlayerSelected(player) {
      this.setPlayerSelected(player);
    },
    onTeamSelected(team) {
      this.setTeam(team.id);
      this.$emit('team-select');
    },
    setPlayerSelected(player) {
      this.playerSelected = player;
      this.setPlayer(this.playerSelected);
      this.$emit('player-select');
    },
  },
};
</script>

<style scoped lang="scss">
$green: #cbee6b;
.play-info {
  font-size: 1rem;
  width: 100%;
  border-radius: 0.5em;
  border: solid 3px $green;
  padding: 0.5em;
  font-family: Roboto-Medium, sans-serif;

  &.play-info-first-break-point {
    & .icn-arrow {
      margin-left: 0.2em;
    }
  }

  &__games {
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    column-gap: 1.25em;

    &.games-first-break-point {
      grid-template-columns: 30% 1fr;
      row-gap: 1em;

      .play-info__games {
        &__rounds__round {
          width: 100%;
        }

        &__game {
          &__teams {
            display: flex;
            align-items: center;
            font-size: 1.1875em;
          }
        }

        &__detail {
          grid-column-start: 1;
          grid-column-end: 3;
          padding: 0.5em 0.2em;
        }
      }
    }

    &.games-second-break-point {
      .play-info__games {
        &__game {
          &__teams {
            & > div:first-child {
              & .vs {
                margin: 0 0.2em;
                font-size: 1em;
              }
            }
          }
        }
      }
    }

    &__rounds__round {
      & > .text {
        font-size: 1.5665em;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.88;
        letter-spacing: -0.25px;
      }
    }

    &__game {
      &__teams {
        display: flex;
        align-items: center;
        font-size: 1.1875em;
        justify-content: center;

        & .vs {
          font-size: 1.5em;
          margin: 0 0.8em;
        }
      }
    }

    &__detail {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4375em;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: -0.23px;
    }
  }

  &__players {
    display: grid;
    grid-template-columns: max-content max-content 1fr min-content;
    column-gap: 1.25em;

    &__teams {
      &__option {
        display: flex;
        align-items: center;
        justify-content: center;

        & > div:last-child {
          font-size: 1.5em;
          margin-left: 0.5em;
        }
      }
    }

    &__list {
      &__option {
        display: flex;
        justify-content: flex-start;
        font-size: 1.3em;
      }
      &__player {
        width: 22em;
        justify-content: space-between !important;

        & > div:first-child {
          font-size: 1.2em;
          margin-left: 0.3em;
        }
      }
    }

    &__detail {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4375em;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: -0.23px;
    }

    &.players-first-break-point {
      grid-template-columns: repeat(10, 1fr);
      row-gap: 1em;

      .play-info__players {
        &__teams {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        &__list {
          grid-column-start: 3;
          grid-column-end: 11;

          &__player {
            width: 100%;
          }
        }

        &__detail {
          grid-column-start: 1;
          grid-column-end: 9;
        }

        &__rival {
          grid-column-start: 9;
          grid-column-end: 11;
          justify-self: flex-end;
        }
      }
    }

    &.players-second-break-point {
      .play-info__players {
        &__teams {
          grid-column-start: 1;
          grid-column-end: 4;
        }

        &__list {
          grid-column-start: 4;
          grid-column-end: 11;
        }
      }
    }

    &.players-third-break-point {
      .play-info__players {
        &__teams {
          grid-column-start: 1;
          grid-column-end: 11;

          & .team-name {
            font-size: 1.5em;
          }
        }

        &__list {
          grid-column-start: 1;
          grid-column-end: 11;
        }

        &__detail {
          grid-column-start: 1;
          grid-column-end: 8;
        }

        &__rival {
          grid-column-start: 8;
          grid-column-end: 11;
        }
      }
    }
  }

  &__teams {
    display: grid;
    grid-template-columns: max-content 1fr min-content;

    &__list {
      &__team {
        display: flex;
        align-items: center;

        & .team-name {
          font-size: 1.2em;
          margin-left: 0.3em;
        }
      }
    }

    &__detail {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4375em;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: -0.23px;
    }

    &.teams-first-break-point {
      grid-template-columns: repeat(10, 1fr);
      row-gap: 1em;

      .play-info__teams {
        &__list {
          grid-column-start: 1;
          grid-column-end: 11;
        }

        &__detail {
          grid-column-start: 1;
          grid-column-end: 9;
          & > span {
            width: 100%;
            white-space: pre-wrap;
          }
        }

        &__rival {
          grid-column-start: 9;
          grid-column-end: 11;
        }
      }
    }

    &.teams-second-break-point {
      grid-template-columns: repeat(10, 1fr);
      row-gap: 1em;

      .play-info__teams {
        &__detail {
          grid-column-start: 1;
          grid-column-end: 8;
        }

        &__rival {
          grid-column-start: 8;
          grid-column-end: 11;
        }
      }
    }
  }

  &__retro {
    display: grid;
    grid-template-columns: 10% max-content 1fr;
    column-gap: 0;

    & > div {
      align-self: center;
      font-size: 1.5em;
    }

    &__round {
      justify-self: left;
      padding-left: 0.8rem;
    }

    &__game {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.retro-first-break-point {
      grid-template-columns: max-content max-content 1fr;
      row-gap: 1em;

      .play-info__retro {
        &__round {
          margin-right: 1rem;
        }
      }
    }

    &.retro-second-break-point {
      grid-template-columns: repeat(10, 1fr);
      .play-info__retro {
        &__round {
          grid-column-start: 1;
          grid-column-end: 4;
          justify-self: center;
        }

        &__game {
          grid-column-start: 4;
          grid-column-end: 11;

          & > div.vs {
            margin: 0 0.2em;
            font-size: 1em;
          }
        }

        &__detail {
          grid-column-start: 1;
          grid-column-end: 11;
        }
      }
    }
  }
}

.selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #bfc9d2;
  height: 3.8rem;
  padding: 0 0.6em;
  cursor: pointer;

  & > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.logo {
  height: 48px;
  width: 48px;

  & img {
    height: 48px;
    width: 48px;
  }
}

.vs {
  margin: 0 0.5em;
}
.category-names {
  font-family: Roboto-Bold, sans-serif;
}
.rival-team {
  display: flex;
  align-items: center;
}

.icn-arrow {
  margin-left: 1.25rem;
}
</style>
<style lang="scss">
.play-info {
  & div.play-info__games__rounds a.dropdown-item {
    width: 100%;
    padding: 0.5em;
    text-align: center;
    font-size: 1.1em;
  }

  & div.play-info__games__game a.dropdown-item {
    padding: 0.375rem 1rem;
  }

  & div.play-info__teams__list a.dropdown-item {
    padding: 0.375rem 1rem;
  }
}
</style>
